.your-child {
  &__heart {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    animation: pulse 2s infinite;
  }

  @keyframes pulse {
    0% {
      transform: scale(0.95);
    }

    50% {
      transform: scale(1.1);
    }

    100% {
      transform: scale(0.95);
    }
  }
}