body {
  .swiper-pagination-fraction,
  .swiper-pagination-custom,
  .swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: 15px;
  }

  .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    opacity: 0.4;
    background-color: white;
  }

  .swiper-pagination-bullet-active {
    opacity: 1;
    background-color: white;
  }

  .swiper-button-prev,
  .swiper-button-next {
    width: 35px;
    height: 35px;

    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;

      right: 6px;
      width: 16px;
      height: 16px;
      border: 2px solid get-color(dark, 1);
      border-top-color: transparent;
      border-left-color: transparent;
      border-radius: 2px;
      content: "";
      transform: rotate(-45deg);
    }

    &.swiper-button-disabled {
      opacity: 0;
    }
  }

  .swiper-button-prev::after {
    right: 0;
    left: 6px;
    border: 2px solid get-color(dark, 1);
    border-radius: 2px;
    border-right-color: transparent;
    border-bottom-color: transparent;
  }

  .newsletter-unsubscribe-input {
    &:focus {
      box-shadow: none;
    }
  }
}
