.products {
  &.has-bg-color {
    position: relative;
    background-color: transparent;
    z-index: auto;

    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 300%;
      height: 240px;
      transform: translateX(-50%);
      background: get-color(dark, 1);
      z-index: -3;
    }
  }

  &-inner {
    @include divider(before, inverse);
  }

  &__swiper {
    display: flex;

    &-product {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-decoration: none !important;

      &_category {
        text-align: center;
        margin-bottom: 44px;
      }

      &_image {
        max-width: 100%;
        max-height: 250px;
        object-fit: contain;

        &-wrapper {
          height: 250px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-bottom: 20px;
        }
      }

      &_title {
        font-size: 16px;
      }

      &_price,
      &_link {
        font-size: 14px;
      }

      &_link {
        margin-top: 20px;
        height: 28px;
        line-height: 28px;
      }
    }
  }
}
.products-center-content {
  padding-bottom: 40px !important;
}

.products.section {
  background: get-color(light, 3);
}

@include media(">medium") {
  .products {
    &.has-bg-color {
      &::before {
        height: 200px;
      }
    }
  }
}
