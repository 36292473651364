.testimonial {

	.tiles-item {
		position: relative;

		&:first-child {

			&::before {
				content: '';
				display: block;
				position: absolute;
				top: -18px;
				left: -10px;
				width: 89px;
				height: 73px;
				background-image: inline-svg('<svg width="89px" height="73px" viewBox="0 0 311 255" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"><path d="M0.5,172.235c0,17.27 3.838,34.539 12.472,49.89c10.554,19.188 32.621,31.661 55.647,31.661c34.539,0 64.28,-27.823 64.28,-62.362l0,-3.838c0,-32.62 -23.026,-56.605 -57.565,-56.605c-3.837,0 -7.675,0 -12.472,0.959c2.878,-67.159 67.159,-108.414 67.159,-108.414l-21.107,-23.026c-50.849,27.823 -77.713,67.159 -94.982,109.373c-7.676,19.189 -13.432,41.255 -13.432,62.362Zm177.492,0c0,17.27 3.838,34.539 12.472,49.89c10.554,19.188 32.62,31.661 56.606,31.661c34.539,0 63.321,-27.823 63.321,-62.362l0,-3.838c0,-32.62 -23.026,-56.605 -57.565,-56.605c-3.837,0 -7.675,0 -12.472,0.959c2.878,-67.159 67.159,-108.414 67.159,-108.414l-20.148,-23.026c-51.808,27.823 -77.712,67.159 -95.941,109.373c-7.676,19.189 -13.432,41.255 -13.432,62.362Z" style="fill:url(#_Linear1);fill-rule:nonzero;stroke:#fff;stroke-width:1px;"/><defs><linearGradient id="_Linear1" x1="0" y1="0" x2="1" y2="0" gradientUnits="userSpaceOnUse" gradientTransform="matrix(-274.647,148.196,-148.196,-274.647,310.391,105.59)"><stop offset="0" style="stop-color:#adb6f2;stop-opacity:1"/><stop offset="1" style="stop-color:#962b56;stop-opacity:1"/></linearGradient></defs></svg>');
			}
		}
	}

	.tiles-item-inner {
		background: get-color(light, 1);
	}
}

.testimonial-item-image {
	position: relative;
	margin-top: 16px;

	img {
		border-radius: 50%;
		box-shadow: 0 12px 24px rgba(get-color(dark, 1), .32);
	}
}

.testimonial-item-name {
	color: get-color(dark, 1);
}

.testimonial-item-link {
	color: #5D6795;
}

@include media( '>1145px' ) { // 1145px may need to be adjusted if testimonial items width or outer padding change
	.testimonial {

		.tiles-wrap {
			padding-bottom: 152px;
		}

		.tiles-item {

			&:first-child {

				&::before {
					top: -136px;
					left: calc(50% - 44px);
				}
			}

			&:nth-child(3n+1) {
				top: 152px;
			}

			&:nth-child(3n+3) {
				top: 64px;
			}
		}
	}
}
