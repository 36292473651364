.clients {

    &-logo {

        transition: all 0.2s ease-out;

        &:hover {
            opacity: 0.5;
        }
    }
}