.pricing {
  &.has-bg-color {
    position: relative;
    background-color: transparent;
    z-index: auto;

    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 300%;
      height: 240px;
      transform: translateX(-50%);
      background: get-color(light, 3);
      z-index: -3;
    }
  }

  .tiles-item-inner {
    background: get-color(light, 1);
  }

  .tiles-item {
    // Illustration fix
    z-index: 1;

    &.illustration-element-07 {
      z-index: 0;
    }
  }

  .form-switch {
    input:checked {
      + .form-switch-icon {
        background: get-color(secondary, 1);
      }
    }
  }

  &-more-info {
    &-container {
      margin-top: 20px;
      margin-bottom: -10px;
      display: flex;
      justify-content: center;
    }

    color: #1f2b4a !important;
    font-size: 15px;
    text-decoration: none !important;
    transition: all 0.15s ease-out;

    &:hover {
      color: #919eb7 !important;
    }
  }
}

.pricing-item-header {
  @include divider(after);
  position: relative;
}

$size: 150px;
.pricing-item-covid-banner {
  position: absolute;
  top: -80px;
  right: -57px;
  background: linear-gradient(
    225deg,
    rgba(152, 121, 159, 1) 0%,
    rgba(140, 57, 91, 1) 100%
  );
  color: white;
  width: $size;
  height: $size;
  border-radius: $size/2;
  box-shadow: 0 32px 88px rgba(get-color(dark, 1), 0.32);
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  transform: scale(0.8);

  & > span {
    transform: rotate(12deg);
  }
}

.pricing-item-header-title-secondary {
  color: color(base);
}

.pricing-item-header-subline {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.pricing-price {
  color: #5ffad0;
  font-size: 36px;
  flex: 0 0 auto;
  margin-top: 10px;
}

ul.pricing-item-features-list {
  @include divider(after);

  li {
    justify-content: space-between;
    margin-bottom: 0;
    padding: 14px 0;
    @include divider(before);
    opacity: 0.5;

    &::after {
      content: "";
      display: block;
      width: 16px;
      height: 12px;
      margin-left: 12px;
      background-image: inline-svg(
        '<svg width="16" height="12" xmlns="http://www.w3.org/2000/svg"><path d="M1 5h14v2H1z" fill="' +
          rgba(get-color(dark, 3), 0.64) + '" fill-rule="nonzero"/></svg>'
      );
      background-repeat: no-repeat;
    }

    &.is-checked {
      opacity: 1;

      &::after {
        background-image: inline-svg(
          '<svg width="16" height="12" xmlns="http://www.w3.org/2000/svg"><path d="M5.6 6.4L1.6 4 0 5.6 5.6 12 16 1.6 14.4 0z" fill="' +
            get-color(alert, success) + '" fill-rule="nonzero"/></svg>'
        );
      }
    }
  }
}

@include media(">medium") {
  .pricing {
    &.has-bg-color {
      &::before {
        height: 200px;
      }
    }
  }

  .pricing-item-covid-banner {
    top: -100px;
    right: -100px;
    transform: scale(1);
  }
}

@include media(">1145px") {
  // 1145px may need to be adjusted if pricing items width or outer padding change
  .pricing {
    .tiles-wrap {
      padding-bottom: 100px;
    }

    .tiles-item {
      &:nth-child(3n + 1) {
        top: 50px;
      }

      &:nth-child(3n + 3) {
        top: 100px;
      }
    }

    &-more-info {
      &-container {
        margin-top: -70px;
        margin-bottom: 30px;
      }
    }
  }
}
