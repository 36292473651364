// products (core/sections/_products.scss) -----------------------
$products--padding-t__mobile:		null;		// products, top padding (mobile)
$products--padding-b__mobile: 		null;		// products, bottom padding (mobile)
$products--padding-t__desktop: 		null;		// products, top padding (desktop)
$products--padding-b__desktop: 		56px;		// products, bottom padding (desktop)
$products-header--padding__mobile: 	null;		// products header, bottom padding (mobile)
$products-header--padding__desktop:	null;		// products header, bottom padding (desktop)
$products-items--padding__mobile: 	null;		// padding between products items (mobile)
$products-items--padding__desktop: 	null;		// padding between products items (desktop)
$products-item--width: 				344px;		// products item, width
$products-item--inner-padding-v: 	24px;		// products item, inner top and bottom padding (it inherits $tiles-item--inner-padding-v unless you replace null with a new value)
$products-item--inner-padding-h: 	24px;		// products item, inner left and right padding (it inherits $tiles-item--inner-padding-h unless you replace null with a new value)
$products-slider--max-w:            320px;      // products slider, max-width