$container-width: $container--width + ($container--padding__desktop * 2);
$banner-left-offset: 24px;
$banner-right-offset: 24px;
$banner-width: 250px;
$collapsed-banner-width: 130px;
$desktop-banner-threshold: 2 *
  ($banner-width + $banner-left-offset + $banner-right-offset) +
  $container-width;

.banner {
  width: $banner-width;
  text-align: center;
  pointer-events: initial;
  margin: 40px 0 0 50px;
  //overflow cut-off fix for nested flexes
  min-height: 1px;

  &:after {
    content: "";
    display: block;
    height: 40px;
  }

  @media (max-width: $desktop-banner-threshold) {
    position: absolute;
    top: 0;
    left: auto;
    margin: 60vh 0 0 0;
    right: 0;
    min-height: 50px;
    z-index: 10;
    width: $collapsed-banner-width;

    .banner__short-content {
      display: block;
    }

    .banner__full-content {
      display: none;
    }
  }

  &--expanded {
    .banner__expand {
      top: 12px;
      left: 12px;
    }

    .banner__inner {
      border-radius: 6px;
      background-image: url("../../../images/sidebanner-background.jpg") !important;
    }

    @media (max-width: $desktop-banner-threshold) {
      right: 50px;
      width: $banner-width;
      margin-top: 40px;

      .banner__short-content {
        display: none;
      }

      .banner__full-content {
        display: block;
      }
    }

    @include media("<=medium") {
      left: 50%;
      right: auto;
      transform: translateX(-50%);
    }
  }

  &__inner {
    padding: 16px 15px;
    color: white;
    border-radius: 8px;
    box-shadow: 0 12px 24px rgba(get-color(dark, 1), 0.32);
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #040a1f url("../../../images/sidebanner-background.jpg")
      no-repeat center top;
    background-size: cover;

    @media (max-width: $desktop-banner-threshold) {
      margin-bottom: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      padding-bottom: 16px;
      background-image: url("../../../images/sidebanner-background-small.jpg");
    }

    &__background-image-small {
      background-image: url("../../../images/sidebanner-background-small.jpg");
    }
  }

  &__full-content {
    display: block;
    position: relative;

    &_supertitle {
      font-family: "Arial";
      font-size: 14px;
      font-weight: 500;
    }

    &_title {
      font-size: 34px;
      color: white;
      margin-top: 0;
      margin-bottom: 0;
    }

    &_title-2 {
      font-size: 18px;
      color: white;
      margin-top: -4px;
      margin-bottom: 176px;
    }

    &_subtitle {
      &-small {
        font-size: 14px;
        font-style: italic;
      }

      &-large {
        font-size: 20px;
        margin-top: -6px;
        margin-bottom: -8px;
      }
    }

    &_date-label {
      color: #aeb9ff;
      margin-top: 14px;
      margin-bottom: 14px;
      font-size: 23px;
    }

    &_separator {
      height: 1px;
      margin: 18px 0;
      background-color: rgba(#7e8ce8, 0.4);
    }

    &_highlights {
      font-family: "Arial";

      &-title {
        font-size: 16px;
        color: #aeb9ff;
        text-transform: uppercase;
      }

      &-list {
        align-items: flex-start;
        list-style: none;
        text-align: left;
        margin-top: 6px;
        padding-left: 12px;

        li {
          margin: 0 0 8px;
          font-size: 14px;
          line-height: 20px;

          display: flex;
          align-items: flex-start;

          .icon {
            width: 11px;
            height: 10px;
            margin-right: 5px;
            margin-top: 3px;
            flex: 0 0 auto;
          }
        }
      }
    }

    &_cta {
      padding-left: 50px;
      padding-right: 50px;
      margin-bottom: 8px;
      margin-top: -4px;

      &-label {
        font-size: 13px;
        font-style: italic;
        margin-top: -4px;
      }
    }

    &_bottom-image {
      height: 58px;
      margin: 16px auto 10px;
    }

    &_footer {
      font-size: 10px;
      line-height: 18px;
      font-style: italic;

      a {
        font-size: 13px;
        color: inherit;
        text-decoration: none;
        font-style: normal;
        &:hover {
          color: inherit;
        }
      }
    }

    &_signup {
      padding-left: 16px;
      padding-right: 16px;

      &-title-wrapper {
        display: flex;
        width: 100%;
        justify-content: space-between;
      }

      &-cancel {
        width: 15px;
        height: 29px;
        cursor: pointer;
      }

      &-title {
        font-size: 16px;
        font-weight: 500;
      }

      &-input {
        height: 36px;
        font-size: 14px;
        line-height: normal;
        padding-top: 8px;
        padding-bottom: 8px;
        padding-left: 12px;
        margin-top: 10px;
        border-radius: 2px;
      }

      &-checkbox {
        font-family: "Arial";
        font-size: 12px;
        color: white;
        line-height: normal;
        margin-top: 10px;
        text-align: left;
        line-height: 14px;

        &-wrapper {
          display: flex;
          flex-direction: column;
          margin-bottom: 14px;
          margin-top: 4px;
        }

        a,
        a:hover {
          color: inherit;
        }
      }
    }

    &_completed-signup {
      &-heart {
        width: 40px;
        margin: 20px auto;
      }

      &-title {
        @include font-family(heading);
        font-size: 30px;
        margin-left: 4px;
        margin-top: -7px;
      }

      &-text {
        text-align: left;
        font-size: 14px;
        padding: 10px 24px 0;
        line-height: 20px;
      }
    }

    &_text {
      font-size: 15px;
      line-height: 20px;
    }

    &_review-cta {
      margin-top: 20px;
    }
  }

  &__short-content {
    display: none;
    font-size: 17px;
    @include font-family(heading);
    line-height: 18px;
    margin-top: 20px;

    &_image {
      position: absolute;
      height: 130px;
      top: -100px;
      right: -30px;
    }

    &_cta {
      padding-left: 12px;
      padding-right: 12px;
      padding-top: 5px;
      padding-bottom: 4px;
      line-height: 16px;
      height: unset;
      line-height: unset;
      margin-top: 12px;
    }
  }

  &__positioner {
    position: relative;
  }

  &-wrapper {
    position: absolute;
    left: $container-width;
  }

  &-container {
    position: fixed;
    width: calc((100vw - #{$container-width}) / 2 + 25px);
    height: 100vh;
    overflow: auto;
    z-index: 10;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (max-width: $desktop-banner-threshold) {
      display: block;
      right: 0;
      width: $collapsed-banner-width + 25px;
      pointer-events: none;

      &--expanded {
        pointer-events: initial;
        width: $banner-width + 75px;
      }
    }
  }

  &__expand {
    cursor: pointer;
    position: absolute;
    top: 8px;
    left: 8px;
    width: 28px;
    height: 28px;
    margin-bottom: 10px;
    align-self: flex-start;

    @media (min-width: $desktop-banner-threshold) {
      display: none;
    }
  }
}
